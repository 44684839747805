<template>
  <section>
    <title-section
      v-if="!loading"
      :title="publication.name"
      :modeEdit="modeEdit"
      returnText="Regresar"
      :returnToFunction="returnPrevView"
      @set-value="setValue"
    >
      <!-- Validacion para saber si hay datos -->
      <template v-if="Object.keys(publicationDetailBackup).length !== empty">
        <template v-if="modeEdit">
          <div class="level-item">
            <button class="button button_micro_dark_outlined" @click="resetUpdate()" :disabled="loading">
              CANCELAR
            </button>
          </div>
          <div class="level-item">
            <button
              class="button button_micro_dark"
              :class="{ 'is-loading' : loading }"
              :disabled="loading"
              @click="updatePublicationAction()"
            >
              GUARDAR
            </button>
          </div>
        </template>
        <template v-else>
          <div class="level-item">
            <button class="button button_micro_dark" @click="modeEdit = true">
              EDITAR
            </button>
          </div>
        </template>
      </template>
    </title-section>
    <template v-if="loading">
      <base-loading />
    </template>
    <template v-else>
      <!-- Validacion para saber si hay datos -->
      <template v-if="Object.keys(publicationDetailBackup).length === empty">
        <section class="hero is-medium">
          <div class="hero-body">
            <div class="container">
              <base-not-data
                title="No hay datos para mostrar"
              >
                <router-link class="button button_micro_dark" to="/publicaciones">
                  REGRESAR
                </router-link>
              </base-not-data>
            </div>
          </div>
        </section>
      </template>
      <template v-else>
        <publication-main-detail
          ref="publicationMain"
          :publicationDetail="publication"
          :modeEdit="modeEdit"
          :categoriesList="categoriesList"
          :loadingCat="loadingCat"
          @set-value="setValue"
          @set-image="setImage"
        />
      </template>
    </template>
  </section>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'PublicationDetail',
  components: {
    BaseLoading: () => import('@/components/UI/Loading.vue'),
    BaseNotData: () => import('@/components/UI/BaseNotData.vue'),
    TitleSection: () => import('@/components/UI/TitleSection.vue'),
    PublicationMainDetail: () => import('@/components/Publications/Detail/PublicationMainDetail.vue')
  },
  data () {
    return {
      publicationId: null,
      modeEdit: false,
      empty: 0,
      ASSETS_URL: process.env.VUE_APP_URL_FILES,
      publication: {
        name: null,
        description: null,
        categoryId: null,
        categoryName: null,
        author: null,
        status: false,
        coverImage: null,
        creationDate: null
      },
      publicationDetailBackup: {},
      imageFile: null,
      categoriesList: [],
      loadingCat: false,
      loading: false
    }
  },
  methods: {
    ...mapActions(['returnPrevView', 'getCategoriesAvailable', 'getPublicationInfo', 'updatePublication']),
    async getPublicationDetail () {
      const { success, payload } = await this.getPublicationInfo(this.publicationId)
      if (success) {
        this.publicationDetailBackup = payload
        await this.spreadData(payload)
      }
      this.loading = false
    },
    spreadData (data) {
      this.publication.name = data.publication_name
      this.publication.description = data.description
      this.publication.categoryId = data.publication_category_id
      this.publication.categoryName = data.publication_category
      this.publication.author = data.author
      this.publication.status = data.active
      this.publication.coverImage = `${this.ASSETS_URL}${data.cover_image}`
      this.publication.creationDate = data.created_at
      return true
    },
    setValue (event) {
      this.publication[event.name] = event.value
    },
    setImage (event) {
      this.imageFile = event
    },
    async resetUpdate () {
      this.loading = true
      await this.spreadData(this.publicationDetailBackup)
      this.modeEdit = false
      this.loading = false
    },
    async updatePublicationAction () {
      await this.$refs.publicationMain.$refs.contentPublication.getLocalContent()
      if (await this.$validator.validateAll()) {
        this.loading = true
        const dataCreate = {
          publicationId: this.publicationId,
          title: this.publication.name,
          description: this.publication.description,
          image: this.imageFile,
          categoryId: this.publication.categoryId,
          author: this.publication.author,
          active: this.publication.status
        }
        const resp = await this.updatePublication(dataCreate)
        if (resp) {
          this.getPublicationDetail()
          this.modeEdit = false
        } else {
          this.loading = false
        }
      }
    }
  },
  async beforeMount () {
    this.publicationId = await this.$route.params.publicationId
    this.loadingCat = true
    const { success, categories } = await this.getCategoriesAvailable()
    if (success) {
      this.categoriesList = categories
      this.getPublicationDetail()
      this.loading = true
    }
    this.loadingCat = false
  }
}
</script>

<style lang="scss" scoped>
  .button {
    font-size: 14px;
    width: 150px;
  }
</style>
